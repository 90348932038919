import shared from "./shared/_shared.js"

const colors = {
  colorWhite:        "#ffffff",
  colorBlack:        "#243242",

  colorBorderLighter:"#f5f5f5",
  colorBorderLight:  "#F0F0F0",
  colorGrey:         "#979797",
  colorGreyDark:     "#5D5D5D",
  colorGreyDarker:   "#2A2A2A",
  colorGreyDarkest:  "#1a1a1a",

  colorBlue:       "#2A6EBB",
  colorYellow:       "#f2d600",
}

const main = {
  ...colors,
  ...shared
}

export default main
