const fonts = {
  // font family
  ffPrimary:        "'space grotesk', 'Roboto', 'Arial', sans-serif",
  ffSecondary:      "'neue-haas-unica', 'Inter', 'Roboto', 'Arial', sans-serif",
  ffWrappNumbers:   "'neue-haas-unica', 'Inter', 'Roboto', 'Arial', sans-serif",


  // font weight
  fwPrimaryNormal:    "100",
  fwSecondaryNormal:  "400",
}

export default fonts
