import React from 'react'
import Link from 'gatsby-link'
import styled, { css } from 'styled-components'
import { rem } from 'polished'
import Headroom from 'react-headroom'

const MainHeader = styled.header`
  width: 100%;
  padding: ${rem(30)};
  margin-left: auto;
  margin-right: auto;

  @media ${props => props.theme.smallUp} {
    padding: ${rem(75)};
  }
  @media ${props => props.theme.mediumUp} {
    padding: ${rem(100)};
  }
  @media ${props => props.theme.largeUp} {
    padding: ${rem(120)};
  }
  @media ${props => props.theme.xlargeUp} {
    padding: ${rem(150)};
  }

  ${props => props.size === 'small' && css`
    padding: ${rem(20)};
    margin-bottom: ${rem(50)};
    background: ${props => props.theme.colorWhite};
    color: ${props => props.theme.colorBlue};
    border-bottom: 1px solid ${props => props.theme.colorBorderLighter};
    position: relative;
    transition: background 0.2s cubic-bezier(0.455, 0.030, 0.515, 0.955);
    z-index: 9;

    small {
      display: none;
    }

    @media ${props => props.theme.smallUp} {
      padding: ${rem(20)};
    }
    @media ${props => props.theme.mediumUp} {
      padding: ${rem(20)};
      height: ${rem(80)};

      a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      strong,
      small {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
      }
      strong {
        transition: transform 0.2s cubic-bezier(0.455, 0.030, 0.515, 0.955);
      }
      small {
        display: inline-block;
        transition: transform 0.2s cubic-bezier(0.455, 0.030, 0.515, 0.955), opacity 0.2s cubic-bezier(0.455, 0.030, 0.515, 0.955);
      }

      small {
        opacity: 0;
      }
    }
    @media ${props => props.theme.largeUp} {
      padding: ${rem(20)};
    }
    @media ${props => props.theme.xlargeUp} {
      padding: ${rem(20)};
    }
    @media ${props => props.theme.xxxlargeUp} {
      padding: ${rem(20)};
      margin-bottom: ${rem(100)};
    }

    &:hover {
      background: ${props => props.theme.colorBlue};
      border-color: ${props => props.theme.colorBlue};
      color: ${props => props.theme.colorWhite};

      strong {
        transform: translateX(-160%) translateY(-50%);
      }
      small {
        opacity: 1;
        transform: translateX(-25%) translateY(-50%);
        transition: transform 0.2s cubic-bezier(0.455, 0.030, 0.515, 0.955), opacity 0.6s cubic-bezier(0.455, 0.030, 0.515, 0.955);
      }
    }
  `}
`

const SiteTitle = styled.h1`
  font-family: ${props => props.theme.ffPrimary};
  font-size: 3.5rem;
  line-height: 1.2;
  font-weight: 300;
  font-kerning: normal;
  letter-spacing: -0.03em;
  color: ${props => props.theme.colorBlue};

  strong {
    font-weight: 600;
  }
  

  @media ${props => props.theme.smallUp} {
    font-size: 4.5rem;
    max-width: 55vw;
    line-height: 1;
  }

  @media ${props => props.theme.xlargeUp} {
    font-size: 6.5rem;
    max-width: 60vw;
  }

  ${props => props.size === 'small' && css`
    width: 100%;
    max-width: 100%;
    font-size: ${rem(18)};
    font-weight: 400;
    line-height: 1;
    transition: border 0.4s;
    display: table;
    transition: background 0.2s;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    @media ${props => props.theme.smallUp} {
      font-size: ${rem(20)};
      width: 100%;
      max-width: 100%;
    }

    @media ${props => props.theme.xlargeUp} {
      font-size: ${rem(24)};
      width: 100%;
      max-width: 100%;
    }
  `}
`
const SiteNavTitle = styled.h1`
  font-family: ${props => props.theme.ffPrimary};
  font-size: ${rem(55)};
  line-height: 1.2;
  font-weight: 400;
 

  strong {
    font-weight: 600;
  }

  a {
    text-decoration: underline;

    &:hover {
      color: ${props => props.theme.colorWhite};
    }
  }
  

  @media ${props => props.theme.smallUp} {
    font-size: 5vw;
    max-width: 55vw;
    line-height: 1;
  }

  @media ${props => props.theme.xlargeUp} {
    font-size: 5vw;
    max-width: 60vw;
  }

  ${props => props.size === 'small' && css`
    width: 100%;
    max-width: 100%;
    font-size: ${rem(25)};
    font-weight: 300;
    line-height: 1;
    transition: border 0.4s;
    display: table;
    transition: background 0.2s;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    @media ${props => props.theme.smallUp} {
      font-size: ${rem(25)};
      width: 100%;
      max-width: 100%;
    }

    @media ${props => props.theme.mediumUp} {
      font-size: ${rem(28)};
      width: 100%;
      max-width: 100%;
    }

    @media ${props => props.theme.xlargeUp} {
      font-size: ${rem(30)};
      width: 100%;
      max-width: 100%;
    }
  `}
`

const SiteByline = styled.p`
  font-family: ${props => props.theme.ffSecondary};
  color: ${props => props.theme.colorBlack};
  font-size: ${rem(24)};
  line-height: 1.3;
  font-weight: 400;
  margin-top: 0.5rem;

  a {
    font-weight: 600;
    text-decoration: underline;
    text-decoration-color: ${props => props.theme.colorYellow};
    text-decoration-thickness: 3px;
    

    &:hover {
      color: ${props => props.theme.colorBlue};
    }
  }

  @media ${props => props.theme.mediumDown} {
    br {
      display: 
    }
  }

  @media ${props => props.theme.smallUp} {
    font-size: ${rem(30)};
    line-height: 1.5;
    margin-top: 0.5rem;
  
  }

  .example_c {
    color: #ffffff !important;
    font-family: ${props => props.theme.ffPrimary};
    font-size: 1.7rem;
    line-height: 1.3;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    background: #2a6ebb;
    border-radius: 6px;
    padding: 1.2rem;
    border: 4px solid #2a6ebb !important;
    max-width: 400px;
    position: relative;
    margin:0;
    margin-bottom: 1rem;
    margin-top: 2rem;
    display:block;
    transition: all 0.2s ease 0s;
    }
   .example_c:hover {
      color: #ffffff !important;
      background: #f2d600;
      border-color: #f2d600 !important;
      transition: all 0.2s ease 0s;
      }


    
`

class Header extends React.Component {
  render() {
    if(this.props.headerSize === "small") {
      return (
        <Headroom>
          <MainHeader size="small">
            <Link to="/" title="Go to homepage">
              <SiteNavTitle size="small">
                <strong>Iancook.design</strong><small>Back to the home page</small>
              </SiteNavTitle>
            </Link>
          </MainHeader>
        </Headroom>
      )
    } else {
      return (
        <MainHeader>
          <SiteTitle><strong>Hello, I'm Ian.</strong></SiteTitle>
          <SiteByline>
           I'm a designer, creative lead and media producer based in Sydney.<br />
           I deliver distinctive ideas, imagery, identities and audio.<br />
           <div class="button_cont" align="flex-start" ><a class="example_c" href="mailto:ian@iancook.design" target="_blank" rel="noreferrer">Get in touch</a></div>
        

          </SiteByline>
        </MainHeader>
      )
    }
  }
}

export default Header
